
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --font-family-pri: "Poppins", sans-serif;
  --font-family-sec: "Big Shoulders Display", sans-serif;
  --font-family-tertiary: "Satisfy", serif;
  --pri-clr: #ffffff;
  --secondary-clr: #1C1C1C;
  --deep-purple-clr: #253bff;
  --line-clr: #8B8B8B;
  --marker-clr-sec: #4151E5;
  --marker-clr: #FA8806;
  --marker-clr-second: #f6fa06;
  --tertiary-clr: #25242A;
  --tertiary-clr-second: #ff0000;
  --all-transitions: all linear .3s;
}

.poppins {
  font-family: var(--font-family-pri) !important;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
   scroll-behavior: smooth;
}

.main-container {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-pri);
  font-size: 1rem;
  margin: 0 auto;
  background-color: var(--secondary-clr);
  color: var(--pri-clr);
}

.hero-sec-wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/img/mentornigeria/hero-section.webp");
  height: 22vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;
  font-size: 6em;
}

.hero-sec-text {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--pri-clr);
  font-weight: 700;
  width: 100%;
  font-family: var(--font-family-sec);
}

.hero-sec-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .6em;
  margin: 0 auto;
  padding: 3rem 4%;
  font-size: 4em;
}

.hero-sec-info li {
  list-style: disc;
}

.apply-now-get-started {
  font-weight: 600;
  font-size: .4em;
  line-height: 1.2em;
  margin: .5em 0;
}

.apply-now-get-started:hover {
  color: var(--marker-clr);
  transition: var(--all-transitions);
}

.line-breaker {
  width: 100%;
  height: 1px;
  background-color: var(--line-clr);
}

.hero-sec-info .section-3 {
  align-self: flex-start;
  font-size: .6em;
  font-weight: 600;
}

/* .hero-sec-info>div:nth-child(3)>span:nth-child(1) {
  font-weight: 600;
  font-size: 1.5em;
  font-family: var(--font-family-sec);
}

.hero-sec-info>div:nth-child(3)>span:nth-child(2) {
  font-weight: 300;
  font-size: .6em;
} */

.hero-sec-info>.section-4 {
  font-size: .4em;
  border: 1px outset var(--line-clr);
  border-left: none;
  background-color: var(--tertiary-clr);
  padding: .5em .5em;
  border-radius: 7px;
}


/* .hero-sec-info>div:nth-child(4)>span:nth-child(1) {
  font-family: var(--font-family-sec);
  color: var(--marker-clr);
  font-size: 1.5em;
} */

.hero-sec-info-list-container {
  display: inline-block;
  padding-left: 1em;
}

.hero-sec-info-list-container .highlighter {
  font-family: var(--font-family-sec);
  font-size: 1.7em;
}

.what-you-learn-title {
  margin-top: .5em;
  text-align: center;
  font-size: .7em;
  max-width: 90%;
  font-family: var(--font-family-sec);
}

.what-you-learn-title>span {
  color: var(--marker-clr);
  font-weight: 800;
}

.what-you-learn-info {
  text-align: left;
  padding: 0 .5em;
  font-size: .35em;
  color: var(--pri-clr);
}

.what-you-learn-info-list-container {
  display: inline-block;
  width: 100%;
  padding: .5em 0 .7em 1em;
}

.highlighter {
  color: var(--marker-clr);
  font-weight: 600;
}

.blue-color {
  color: #405dff;
}

/* WHATSAPP CONTAINER  */
.whatsapp-btn-container {
  position: fixed;
  padding: 10px;
  right: -5px;
  bottom: 0px;
  font-size: 4em;
  z-index: 10;
  
}

.whatsapp-btn-container .whatsapp-btn {
  font-size: .8em;
  color: #25d366;
}

.whatsapp-btn-container span {
  position: absolute;
  font-size: .25em;
  top: 15px;
  left: 4px;
  color: #25d366;
}

.news-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background-color: var(--marker-clr);
  overflow: hidden;
  font-size: 4em;
}

.news-bar-container .news {
  font-weight: 600;
  position: absolute;
  top: 0;
  width: max-content;
  font-size: .25em;
  color: var(--secondary-clr);
  animation: scrolling 24s linear infinite;
}



@keyframes scrolling {
  0% {right: -60%;}
  100% {
    right: 100%;
  }
}



.apply-now-link-2 {
  border-radius: 7px;
  border: 1px solid var(--pri-clr);
  background-color: var(--marker-clr);
  font-size: .4em;
  padding: .3em .4em;
  color: var(--tertiary-clr);
  transition: var(--all-transitions);
  cursor: pointer;
}

.apply-now-link-2:hover {
  background-color: var(--marker-clr-sec);
  color: var(--pri-clr);
}

.apply-now-link-2>a {
  display: inline-block;
  height: 100%;
  width: 100%;
}


#application-form-section {
  position: relative;
  text-align: center;
  font-size: 4em;
  background-color: var(--pri-clr);
  color: var(--secondary-clr);
  border: 1px solid var(--pri-clr);
  font-family: var(--font-family-sec);
  padding: .3em 0;
  z-index: 1000;
  
}

.application-form-title {
  font-size: .6em;
  font-weight: 800;
  margin-bottom: .5em;
}

#application-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: .3em;
  padding-bottom: .3em;
  max-width: 800px;
  border-radius: 7px;
  width: 90%;
  background-color: var(--tertiary-clr);
  margin: 0 auto;
  color: var(--pri-clr);
}

.form-row-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
  font-size: .3em;
  font-family: var(--font-family-pri);
  padding: 1em .5em;
}

.form-row {
  position: relative;
  width: 48%;
}

.form-row-consent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.consent-text {
  text-align: left;
  font-size: .8em;
  line-height: 1em;
}

.input-labels {
  position: absolute;
  top: -80%;
  font-size: .8em;
}

.input-labels-textarea {
  position: absolute;
  top: -40%;
  font-size: .8em;
  width: 100%;
  text-align: left;
}

.form-control {
  width: 100%;
  height: 2em;
  font-size: .8em;
  font-weight: 400;
  padding: 0 .3em;
  color: var(--secondary-clr);
  border-radius: 3px;
}

.form-control-textarea {
  min-width: 208%;
  min-height: 4em;
  max-width: 100%;
  max-height: 4em;
  font-size: .8em;
  font-weight: 400;
  padding: 0 .3em;
  color: var(--secondary-clr);
  border-radius: 3px;
}

.submit-btn {
  font-size: .35em;
  background-color: var(--marker-clr);
  color: var(--pri-clr);
  border-radius: 5px;
  border: 1px outset var(--pri-clr);
  padding: .3em .5em;
  cursor: pointer;
}

.apply-btn-container {
  padding-top: .5em;
}

#apply-btn {
  color: var(--pri-clr);
  font-size: .4em;
  border-color: inherit;
  background-color: var(--marker-clr);
}



.form-info-box {
  background-color: var(--pri-clr);
  color: var(--marker-clr);
  font-size: .3em;
  font-family: var(--font-family-pri);
  padding: .2em;
  border-radius: 3px;
  font-weight: 600;
}


.module-section-wrapper {
  text-align: center;
  margin: .5em auto;
  width: 90%;
  font-size: 4em;
}

.program-section-wrapper {
  text-align: center;
}

.module-title {
  font-size: .5em;
  /* font-family: var(--font-family-sec); */
  font-weight: 600;
}

.module-text-box {
  font-size: .5em;
  text-align: left;
  margin-top: .5em;
}

.module-text-box h1 {
  font-size: .7em;
  font-weight: bold;
  text-decoration: underline;
}

.module-text-box p {
  font-size: .65em;
}

.module-text-box__list-box {
  list-style-type: decimal;
  font-size: .65em;
  padding-left: .7em;
}

.module-text-box__list-box-inner-box {
  list-style-type: disc;
  font-size: 1em;
  padding-left: .7em;
  margin-bottom: .5em;
}

.module-row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: .5em;
  padding-top: .5em;
}

.module-info {
  font-size: .3em;
  width: 45%;
  min-width: 323px;
  padding: 1em 1em 1em 1em;
  border: 1px solid var(--line-clr);
  border-radius: 7px;
  text-align: left;
  background-color: var(--tertiary-clr);
  
}

/* .module-info li {
  list-style: disc;
} */

.long-text li {
  list-style: disc;
}

/* .three-dot {
  display: none;
} */

.long-text {
  /* padding-left: 1.15em; */
  margin-top: 1em;
}

.read-more-btn {
  display: none;
}

.sub-module-list {
  display: inline-block;
  padding-left: 1em;
  margin-top: .3em;
}

.sub-module-list li {
  /* padding-left: 1em; */
  list-style: circle;
}


.module-info:hover {
  border-color: var(--marker-clr);
}

.footer-section-wrapper {
  background-color: var(--marker-clr);
  color: var(--secondary-clr);
  padding: 1.2rem 8%;
  font-size: 4em;
}

.footer-section-wrapper>div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  gap: .2em;
  font-size: .3em;
}

.footer-section-wrapper>div:nth-child(2) {
  font-size: .3em;
}


.f-section-col {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  gap: .2em;
  max-width: 500px;
}

.phone {
  align-items: center;
  min-width: 108px;
}

.long-text {
  /* max-height: 0; */
  overflow: hidden;
  transition: var(--all-transitions);
  /* padding-left: 1.15em;
  margin-top: 1em; */
}

.read-more-btn {
  display: inline-block;
  color: var(--marker-clr);
}

.three-dot {
  display: block;
  font-size: 1.5em;
  line-height: .3em;
  font-weight: 600;
  letter-spacing: .15em;
  margin-bottom: 1rem;
}


@media (max-width: 796px) {
  .hero-sec-wrapper {
    height: 25vw;
    font-size: 4em;
  }

  .hero-sec-info {
    font-size: 3em;
  }

  .application-form-title {
    font-size: 0.35em;
  }

  .module-section-wrapper {
    font-size: 3em;
  }

  .module-info {
    width: 90%;
  }

  /* .three-dot-gone {
    display: none;
  } */

  .footer-section-wrapper {
    font-size: 3em;

  }

  @keyframes scrolling {
    0% {right: -100%;}
    100% {
      right: 100%;
    }
  }
}

@media (max-width: 579px) {
  .hero-sec-wrapper {
    height: 37vw;
    font-size: 2.5em;
  }

  .hero-sec-info {
    font-size: 2.5em;
    padding: 1.5rem 4%;
  }

  .form-row-main {
    flex-direction: column;
    gap: 2em;
  }

  .form-row {
    width: 100%;
  }

  .form-control-textarea {
    min-width: 100%;
  }

  .whatsapp-btn-container {
    font-size: 3em;
  }

  @keyframes scrolling {
    0% {right: -200%;}
    100% {
      right: 100%;
    }
  }
}
