@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --font-family-pri: "Poppins", sans-serif;
  --font-family-sec: "Big Shoulders Display", sans-serif;
  --pri-clr: #FEFEFE;
  --secondary-clr: #1C1C1C;
  --line-clr: #8B8B8B;
  --marker-clr-sec: #4151E5;
  --marker-clr: #FA8806;
  --tertiary-clr: #25242A;
  --all-transitions: all linear .3s;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
   scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-pri);
  font-size: 1rem;
  margin: 0 auto;
  background-color: var(--secondary-clr);
  color: var(--pri-clr);
}

.career-hero-sec-wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/img/mentornigeria/hero-section.webp");
  height: 22vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;
  font-size: 6em;
}

.career-hero-sec-text {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--pri-clr);
  font-weight: 700;
  width: 100%;
  font-family: var(--font-family-sec);
}



.career-hero-sec-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: .6em;
  margin: 0 auto;
  padding: 3rem 4%;
  font-size: 4em;
}

.career-hero-sec-info>div:nth-child(1) {
  font-weight: 600;
  font-size: .5em;
  line-height: 1.2em;
}

.career-hero-sec-info>div:nth-child(1):hover {
  color: var(--marker-clr);
  transition: var(--all-transitions);
}

.line-breaker {
  width: 100%;
  height: 1px;
  background-color: var(--line-clr);
}

.career-hero-sec-info>div:nth-child(3) {
  font-size: .4em;
}

.career-hero-sec-info>div:nth-child(4),
.career-hero-sec-info>div:nth-child(5) {
  font-size: .4em;
  border: 1px outset var(--line-clr);
  border-left: none;
  background-color: var(--tertiary-clr);
  padding: .5em .5em;
  border-radius: 7px;
}


.career-hero-sec-info>div:nth-child(4)>span:nth-child(1),
.career-hero-sec-info>div:nth-child(5)>span:nth-child(1) {
  color: var(--marker-clr);
  font-size: 1.2em;
  font-weight: 600;
}

.career-hero-sec-info-list-container {
  display: inline-block;
  padding-left: 1em;
}

.career-hero-sec-info-list-container .highlighter {
  font-family: var(--font-family-sec);
  font-size: 1.7em;
}

.career-what-you-learn-title {
  font-size: .4em;
}

.paragraph-title {
  font-weight: 600;
  font-size: 1.2em;
  text-decoration: underline;
}

#career-what-you-learn-title-list {
  display: flex;
  list-style: decimal;
  flex-direction: column;
  padding-left: 1em;
  margin-top: .2em;
  gap: .35em;
}

/* .career-what-you-learn-title>span {
  color: var(--marker-clr);
  font-weight: 800;
  font-size: 1.2em;
} */

/* .career-what-you-learn-info {
  text-align: left;
  padding: 0 .5em;
  font-size: .4em;
  color: var(--pri-clr);
}

.career-what-you-learn-info-list-container {
  display: inline-block;
  width: 100%;
  padding-left: 1em;
  padding-top: .5em;
} */

.career-what-you-learn-info-list-container-inner-list-container {
  display: flex;
  flex-direction: column;
  gap: .3em;
  list-style: disc;
  padding-left: 1em;
  font-size: .95em;
  margin-bottom: 0.5em;
}

.highlighter {
  color: var(--marker-clr);
  font-weight: 600;
}

.highlighter-big {
  font-family: var(--font-family-sec);
  color: var(--marker-clr);
  font-weight: 600;
  font-size: 1.3em;
}

.career-other-hero-section-container {
  font-size: .4em;
}

.spacing {
  margin-top: .7em;
  margin-bottom: .7em;
}

/* WHATSAPP CONTAINER  */
.whatsapp-btn-container {
  position: fixed;
  padding: 10px;
  right: -5px;
  bottom: 0px;
  font-size: 4em;
  z-index: 10;
  
}

.whatsapp-btn-container .whatsapp-btn {
  font-size: .8em;
  color: #25d366;
}

.whatsapp-btn-container span {
  position: absolute;
  font-size: .25em;
  top: 15px;
  left: 4px;
  color: #25d366;
}

.career-footer-section-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: var(--marker-clr);
  color: var(--secondary-clr);
  padding: 1rem 4%;
  font-size: 4em;
  gap: .2em;
}

.career-f-section-col {
  display: flex;
  flex-direction: column;
  font-size: .3em;
  gap: .5em;
}

#consent-container {
  display: none;
}

#hear-container {
  display: none;
}

.call-to-action {
  align-self: center;
  font-size: .35em;
  color: white;
  font-weight: 600;
  background-image: linear-gradient(to right, var(--marker-clr), var(--marker-clr-sec));
  padding: .4em .5em;
  border-radius: 3px;
  transition: var(--all-transitions);
  cursor: pointer;
}

.call-to-action:hover {
  background-image: linear-gradient(to right, var(--marker-clr) 100%, var(--marker-clr-sec) 0%);
}

@media (max-width: 796px) {
  .career-hero-sec-wrapper {
    height: 25vw;
    font-size: 4em;
  }

  .career-hero-sec-info {
    font-size: 3em;
  }

  .career-footer-section-wrapper {
    font-size: 3em;
  }
}

@media (max-width: 579px) {
  .career-hero-sec-wrapper {
    height: 37vw;
    font-size: 2.5em;
  }

  .career-hero-sec-info {
    font-size: 2.5em;
    padding: 1.5rem 4%;
  }

  .whatsapp-btn-container {
    font-size: 3em;
  }

}

