@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

:root {
  --font-family-pri: "Poppins", sans-serif;
  --font-family-sec: "Big Shoulders Display", sans-serif;
  --font-family-tertiary: "Satisfy", serif;
  --pri-clr: #ffffff;
  --secondary-clr: #1C1C1C;
  --deep-purple-clr: #253bff;
  --line-clr: #8B8B8B;
  --marker-clr-sec: #4151E5;
  --marker-clr: #FA8806;
  --marker-clr-second: #f6fa06;
  --tertiary-clr: #25242A;
  --tertiary-clr-second: #ff0000;
  --all-transitions: all linear .3s;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.yellow-text {
  color: var(--marker-clr-second);
}

.red-text {
  color: var(--tertiary-clr-second);
}

.bold {
  font-weight: 600;
}

.small-text {
  font-size: 0.85em;
}

.deep-purple {
  color: var(--deep-purple-clr);
}

.school-growth-main-container .divider {
    min-width: 100%;
    height: 2px;
    background-color: var(--marker-clr-sec);
}

.school-growth-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--pri-clr);
  font-size: 1rem;
  overflow-x: hidden;
}

.main-section {
  flex: 3;
  height: auto;
  margin: 3em 3% 0 3%;
  font-size: 1em;
}

.title-container {
  background-color: var(--marker-clr-sec);
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.title-container h1 {
  font-size: 2em;
  color: var(--pri-clr);
  padding: 0.8em 2%;
}

.title-container .second-text {
  display: inline-block;
}

.title-logo-body {
  align-self: stretch;
  max-width: 80px;
  background-color: var(--pri-clr);
  display: none;
  align-items: center;
  justify-content: center;
  border: none;
}

.text-body-container {
  padding-top: 1em;
  font-size: 1em;
}

.text-body ul {
  list-style-type: decimal;
  padding-left: 1em;
  font-size: 1.4em;
}

.text-body ul > li {
  line-height: 1.3em;
  margin-bottom: 0.6em;
}

.price-body {
  margin: 1em 0;
  font-size: 2em;
  line-height: 1em;
}

.price-body .title {
    font-family: var(--font-family-tertiary);
    font-size: 1em;
}

.price-body .amount {
  font-size: 1em;
  margin-left: 0.15em;
  font-family: var(--font-family-sec);
  font-weight: 800;
}

.note-body {
  font-size: 1.4em;
  line-height: 1.3em;
}

.interest-body {
    text-align: center;
    font-size: 1.4em;
    margin: 1em 0;
}

.interest-body a {
    background-color: var(--deep-purple-clr);
    color: var(--pri-clr);
    padding: .5em 1em;
    border-radius: 3px;
}

.contact-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
}

.contact-body {
  margin-top: 1em;
  font-size: 1.4em;
}

.contact-body a {
  display: block;
  /* margin-right: 0.5em; */
}

.small-screen-contact {
    display: block;
}

.secondary-section {
  align-self: stretch;
  flex: 1;
  background-color: var(--marker-clr-sec);
  text-align: center;
  padding: 1.5em 1%;
  font-size: 1.9em;
  color: var(--pri-clr);
}

.logo-body {
  width: 100%;
  max-width: 200px;
  background-color: var(--pri-clr);
  border: none;
  border-radius: 50%;
  padding: 4%;
  margin: 0 auto;
}

.logo-body img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote-body {
  margin: 2em 0;
  font-size: 1em;
}

.join-body {
  font-size: 1em;
  margin-bottom: 2em;
}

.address-body {
  font-size: 0.8em;
  text-align: left;
}

@media screen and (max-width: 730px) {
    .school-growth-main-container {
        padding-bottom: 3rem;
    }

    .break-line {
        display: none;
    }

    .main-section {
        margin: 0;
        max-width: 100vw;
    }

    .title-container h1 {
        padding: 0.8em 0.5em;
    }

    .text-body-container {
        padding: 1em 3%;
    }

    .secondary-section {
        display: none;
    }

    .interest-body {
        font-size: 1.2em;
    }

    .interest-body a {
        display: inline-block;
        min-width: 100%;
        padding: .5em 0;
    }

    .contact-section {
      flex-direction: column;
      padding: 0 .6em;
    }

    .contact-body a {
        margin: 0;
        /* display: block; */
    }

    .big-screen-contact {
        display: none;
    }

    /* .small-screen-contact {
        display: block;
    } */
}

@media screen and (max-width: 480px) {
    .school-growth-section {
        font-size: 1rem;
    }

    .title-container {
        font-size: .9em;
    }

    .title-container .second-text {
      display: block;
      text-align: center;
    }

    .price-body {
        font-size: 2.1em;
        margin-left: 0.15em;
    }

    .price-body .amount {
        display: block;
        margin-top: 0.15em;
        margin-left: 0;
    }
}
